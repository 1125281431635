import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/24/solid";
import { classNames } from "../utils/formatters";
import Avatar from "./Avatar";
import ContactButton from "./ContactBussinesButton";
import SearchField from "./SearchField";
import CollectionBanner from "./collection/Banner";
import { TIER_KEYS } from "../utils/constants";
import VerifiedUserBadge from "./VerifiedUserBadge";

function parseWebsite(website: string) {
  if (website.includes("https://")) {
    return website.split("https://")[1];
  } else return website;
}

export default function ListUserHeader({ user }) {
  const {
    about,
    banner,
    location,
    logo,
    name: companyName,
    website,
  } = user.company || {};

  const name = companyName ? companyName : user.displayName;
  const image = user.company && logo ? logo : user.image;

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between md:space-x-5 ">
        <div>
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <Avatar
                  src={image}
                  username={logo ? logo : user.username}
                  size="large"
                />
                <span
                  className="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900 flex items-center">
                {name}
                {user.company ? (
                  <BuildingOfficeIcon className="ml-1 h-6 w-6" />
                ) : null}
              </h1>
              <VerifiedUserBadge idVerified={user.idVerified} />
            </div>
          </div>
        </div>
        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          {/* <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 focus:ring-offset-gray-100"
          >
            <ShareIcon className="h-4 w-4" />
          </button> */}
          {website ? (
            <a
              href={`https://${parseWebsite(website)}`}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 focus:ring-offset-gray-100"
            >
              <GlobeAltIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Sitio web
            </a>
          ) : null}

          {user.company && companyName ? (
            <ContactButton
              seller={user}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 focus:ring-offset-gray-100"
              label="Contactar"
            />
          ) : null}
        </div>
      </div>
      {user?.username === TIER_KEYS.COLLECTION ? (
        <div className="border-t border-gray-200 py-3 mt-3">
          <CollectionBanner />
        </div>
      ) : (
        <div
          className={classNames(
            about || location ? "border-t border-gray-200 py-3 mt-3" : ""
          )}
        >
          {about ? <p className="text-sm text-gray-600">{about}</p> : null}
          {location && location.address ? (
            <p className="text-sm text-gray-600 mt-2">
              <span className="font-medium">Direccion: </span>
              {location.address ? `${location.address}, ` : ""}
              {location.municipality ? `${location.municipality}, ` : ""}
              <span className="capitalize">{location.province || ""}</span>.
            </p>
          ) : null}
        </div>
      )}

      <div className="mt-2">
        <SearchField
          pathname={`/${user.username}`}
          defaultPlaceholder={`Buscar autos vendidos por ${name}`}
        />
      </div>
    </div>
  );
}
