import APP_CONFIG from "../../app.config";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import Layout from "../../components/Layout";
import Grid from "../../components/Grid";
import {
  CREDIQ_PRE_APROV,
  SlugMetadata,
  TIER_KEYS,
  getSlugMetadata,
} from "../../utils/constants";
import { useGetVehiclesQuery, User } from "../../graphql/generated/graphql";
import SortDropdown from "../../components/SortDropdown";
import ListPagination from "../../components/ListPagination";
import { useSession } from "next-auth/react";
import ListUserHeader from "../../components/ListUserHeader";
import StoreBanner from "../../components/StoreBanner";
import FavoriteSearchButton from "../../components/FavoriteSearchButton";
import FavoriteSearchNotifyButton from "../../components/FavoriteSearchNotifyButton";
import SEO from "../../components/SEO";
import Banners from "../../components/Banners";
import CollectionSubscriptionSection from "../../components/collection/SubscriptionSection";
import { capitalizeAllWords } from "../../utils/formatters";
import { EmptyState } from "../../components/EmptyState";
import Filters from "../../components/filters/Filters";
import { getVariables } from "../../utils/list";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";
import SearchField from "../../components/SearchField";

// REFACTORING: may stay in parent
const getSEOTitle = (username?: string, slugMetadata?: SlugMetadata) => {
  if (username && !slugMetadata) {
    return `Autos vendidos por ${capitalizeAllWords(username as string)}`;
  } else if (slugMetadata) {
    return slugMetadata.seo.title;
  }

  return "Listado de autos usados en Costa Rica";
};

// REFACTORING: may stay in parent
const getSEODescription = (username, slugMetadata) => {
  if (username && !slugMetadata) {
    return `Página de ventas de ${capitalizeAllWords(username as string)}`;
  } else if (slugMetadata) {
    return slugMetadata.seo.description;
  }

  return "Las mejores ofertas de autos usados en Costa Rica";
};

export default function List({
  query,
  watchedUser,
  slug,
}: {
  query: TQueryString;
  watchedUser: User;
  slug?: string;
}) {
  const { data: sessionData } = useSession();
  const { user: currentUser } = sessionData || {};
  const currentUserId = currentUser?.id;
  const isCurrentUser = currentUserId && currentUserId === watchedUser?._id;

  const slugMetadata = getSlugMetadata(slug);

  const router = useRouter();
  const queryObject = router.query || query;
  const showCrediQBanner =
    APP_CONFIG.partners.crediq.enabled && router.query?.slug === "crediq";

  const { search, page } = queryObject;

  const variables = getVariables(queryObject, watchedUser);

  // REFACTORING: to new query hook
  const { data, loading } = useGetVehiclesQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles } = data || {};
  const { data: vehicles, meta } = getVehicles || {};
  const { total, pageSize } = meta || {};

  return (
    <div className="bg-white sm:pt-4">
      {/* REFACTORING: stays in parent */}
      <SEO
        currentURL={`${process.env.NEXT_PUBLIC_ROOT_URL}/autosusados`}
        title={getSEOTitle(watchedUser?.username, slugMetadata)}
        description={getSEODescription(watchedUser?.username, slugMetadata)}
        siteTitle="Movi Autos"
      />
      <div>
        {showCrediQBanner ? (
          <div className="max-w-7xl mx-auto sm:px-4 mb-4">
            {" "}
            <StoreBanner
              src={"/partners/banner-crediq-page.jpg"}
              href={CREDIQ_PRE_APROV}
              alt="Financiá tu auto con CrediQ"
            />
          </div>
        ) : null}
        {/* REFACTORING: stays in parent */}
        {watchedUser?.company?.banner ? (
          <div className="max-w-7xl mx-auto sm:px-4 mb-4">
            {" "}
            <StoreBanner src={watchedUser?.company?.banner} />
          </div>
        ) : null}

        {/* REFACTORING: stays in parent */}
        {!watchedUser?.company?.banner ? <Banners /> : null}

        <main className="max-w-7xl mx-auto px-4">
          {/* REFACTORING: stays in parent */}
          {watchedUser ? <ListUserHeader user={watchedUser} /> : null}

          {/* REFACTORING: create reusable component for this and use in both /autosusados and /connect */}
          <div className="py-6">
            {showCrediQBanner && (
              <div className="mb-4">
                <div className="flex flex-col sm:flex-row justify-between items-center sm:items-baseline gap-8 sm:gap-0 w-full">
                  <div className="relative mt-2">
                    <Image
                      src="/partners/logo_crediq.png"
                      alt="CrediQ Logo"
                      height={87}
                      width={300}
                    />
                  </div>
                  <Link href={CREDIQ_PRE_APROV} target="_blank">
                    <button className="bg-sky-700 text-white flex gap-3 items-center text-lg sm:text-xl font-semibold py-2 px-4 rounded-full hover:bg-sky-600 transition animate-bounce">
                      <CursorArrowRaysIcon className="h-6 w-6" />
                      Pre aprobate con CrediQ
                    </button>
                  </Link>
                </div>
                <div className="mt-2">
                  <SearchField
                    pathname={`/crediq`}
                    defaultPlaceholder={`Buscar autos financiables por CrediQ`}
                  />
                </div>
              </div>
            )}
            <div className="relative z-10 flex items-center justify-between flex-wrap">
              <div>
                {/* {search ? (
                  <>
                    <h1 className="text-2xl">{getSEOTitle(watchedUser?.username, slugMetadata)}</h1>
                    <span className="text-lg">
                      {loading ? (
                        "Buscando "
                      ) : (
                        <span>
                          {total || 0} resultados {search ? "para" : ""}{" "}
                        </span>
                      )}

                      <span className="font-bold">&quot;{search}&quot;</span>
                    </span>
                  </>
                ) : (
                  <h1 className="text-2xl">
                    {getSEOTitle(watchedUser?.username, slugMetadata)}
                  </h1>
                )} */}

                {/* REFACTORING: these stay in parent */}

                <h1 className="text-2xl">
                  {getSEOTitle(watchedUser?.username, slugMetadata)}
                </h1>
                <p className="text-gray-500 my-2">
                  {showCrediQBanner &&
                    getSEODescription(watchedUser?.username, slugMetadata)}
                </p>
                <h2 className="text-lg text-gray-700">
                  {loading ? (
                    "Buscando "
                  ) : (
                    <span>
                      {total || 0} resultados{" "}
                      {search || slugMetadata?.id ? "para" : ""}{" "}
                    </span>
                  )}

                  {search || slugMetadata?.id ? (
                    <span className="font-bold">
                      &quot;{search || slugMetadata?.id}&quot;
                    </span>
                  ) : null}
                </h2>
                <div className="flex flex-col sm:flex-row gap-x-3 gap-y-2">
                  <FavoriteSearchButton queryObject={queryObject} />
                  <FavoriteSearchNotifyButton queryObject={queryObject} />
                </div>
              </div>

              <SortDropdown
                className="hidden sm:inline-block"
                query={queryObject}
              />
            </div>

            <Filters query={query} watchedUser={watchedUser} slug={slug} />
          </div>

          <section aria-labelledby="results-heading">
            <h2 id="results-heading" className="sr-only">
              Resultados
            </h2>

            <div>
              <ListPagination
                loading={loading}
                page={page ? Number(page) : 1}
                // setPage={setPage}
                total={total}
                pageSize={pageSize}
              />
              {/* REFACTORING: stays in parent - each parent defines it's own list renderer component  */}
              <Grid
                items={vehicles}
                loading={loading}
                isCurrentUser={isCurrentUser}
                noPadding
                EmptyStateComponent={EmptyState}
              />
              <ListPagination
                loading={loading}
                page={page ? Number(page) : 1}
                // setPage={setPage}
                total={total}
                pageSize={pageSize}
              />
            </div>
          </section>
          {/* <HorizontalAdBanner /> */}

          {/* REFACTORING: stays in parent */}
          <div className="mt-10">
            {slug === TIER_KEYS.COLLECTION ? (
              <CollectionSubscriptionSection
                vehicleId={null}
                className="rounded-lg"
              />
            ) : (
              <Banners />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

List.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};
