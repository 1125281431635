import Image from "next/image";
import Link from "next/link";

export default function StoreBanner({
  src,
  href,
  alt = "Banner de negocio en Movi",
}: {
  src: string;
  href?: string;
  alt?: string;
}) {
  return (
    <div className="relative aspect-[16/4] lg:aspect-auto bg-gray-200 flex items-center justify-center sm:mx-auto sm:px-4 md:h-42 lg:h-72 lg:w-full">
      {/* <span className="text-white px-1 uppercase font-bold text-3xl">
        Espacio publicitario
      </span> */}
      {href ? (
        <Link href={href} target="_blank">
          <Image
            src={src}
            className="object-center object-cover cursor-pointer sm:rounded-lg shadow-lg"
            alt={alt}
            fill
            style={{
              objectFit: "cover",
            }}
          />
        </Link>
      ) : (
        <Image
          src={src}
          className="object-center object-cover sm:rounded-lg shadow-lg"
          alt={alt}
          fill
          style={{
            objectFit: "cover",
          }}
        />
      )}
    </div>
  );
}
