import { useGetUserQuery, User } from "../graphql/generated/graphql";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSession } from "next-auth/react";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMessage, AiOutlinePhone } from "react-icons/ai";
import { event } from "../lib/gtag";
import { cleanupPhoneNumber } from "../utils/formatters";
import VerifiedUserIcon from "./VerifiedUserIcon";
import Link from "next/link";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

type TProps = {
  open?: boolean;
  setOpen: (open?: boolean) => void;
  seller: User;
};

function getContactMessage(username) {
  const msg = `¡Hola! Vi tu página en Movi. Estoy interesado en un auto.
    ${
      username
        ? `\nMi nombre de usuario en Movi es: ${username}`
        : "\nNo estoy registrado en Movi."
    }`;
  const encodedMsg = encodeURIComponent(msg);
  return encodedMsg;
}

export default function ContactModal({ open, setOpen, seller }: TProps) {
  const { status: sessionStatus, data: sessionData } = useSession();
  const { user: currentUser } = sessionData || {};
  const isYourIdVerified = currentUser?.idVerified;

  const { data: getSellerQuery, loading } = useGetUserQuery({
    variables: {
      _id: seller._id,
    },
  });

  const { getUser: getSellerContact } = getSellerQuery || {};
  const { primaryPhone, secondaryPhone } = getSellerContact || {};
  // const parsedPhoneNumber = cleanupPhoneNumber(primaryPhone);
  // const parsedSecondaryPhoneNumber = cleanupPhoneNumber(secondaryPhone);

  const contactMsg = getContactMessage(currentUser?.username);

  function onWhatsappClick() {
    event({
      category: "Contact",
      action: "click_seller_whatsapp",
      label: `${seller.username}`,
    });
  }

  function onSMSClick() {
    event({
      category: "Contact",
      action: "click_seller_sms",
      label: `${seller.username}`,
    });
  }

  function onCallClick() {
    event({
      category: "Contact",
      action: "click_seller_call",
      label: `${seller.username}`,
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                {/*  This is duplicated in ContactButtons */}
                {/* {(!isYourIdVerified && sessionStatus === "authenticated") ||
                sessionStatus === "unauthenticated" ? (
                  <div className=" py-2 ">
                    <div className="flex items-center justify-center gap-x-1">
                      <VerifiedUserIcon
                        idVerified={isYourIdVerified}
                        className="h-7 w-7"
                        triangle
                      />
                      <p className="text-lg">No has verificado tu identidad</p>
                    </div>
                    <p className="text-sm text-gray-600 mt-2 ml-2">
                      Para dar más seguridad y confianza al vendedor,
                      <Link href="/profile/verify">
                        <span className="text-cyan-500 hover:text-cyan-400 inline-flex gap-x-1 items-center cursor-pointer font-medium">
                          verificá tu identidad
                          <ArrowTopRightOnSquareIcon className="h-4 w-4 " />
                        </span>
                      </Link>
                      . El vendedor puede solicitar tu nombre de usuario para
                      verificar tu información.
                    </p>
                  </div>
                ) : null} */}
                {/*  ----------------------------------------- */}
                <div className="text-center">
                  <p className="leading-6 text-gray-600">Contactar vendedor</p>
                </div>

                <a
                  type="button"
                  className="mt-2 inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm disabled:opacity-70"
                  href={`https://wa.me/${primaryPhone}?text=${contactMsg}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={onWhatsappClick}
                >
                  <BsWhatsapp className="h-5 w-5" />
                  Whatsapp
                </a>

                {secondaryPhone ? (
                  <a
                    type="button"
                    className="mt-2 inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm disabled:opacity-70"
                    href={`https://wa.me/${secondaryPhone}?text=${contactMsg}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={onWhatsappClick}
                  >
                    <BsWhatsapp className="h-5 w-5" />
                    Whatsapp secundario
                  </a>
                ) : null}

                <a
                  type="button"
                  className="mt-2 inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm disabled:opacity-70"
                  href={`sms:${primaryPhone}&body=${contactMsg}`}
                  onClick={onSMSClick}
                >
                  <AiOutlineMessage className="h-5 w-5" />
                  SMS
                </a>

                {secondaryPhone ? (
                  <a
                    type="button"
                    className="mt-2 inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm disabled:opacity-70"
                    href={`sms:${secondaryPhone}&body=${contactMsg}`}
                    onClick={onSMSClick}
                  >
                    <AiOutlineMessage className="h-5 w-5" />
                    SMS secundario
                  </a>
                ) : null}

                <a
                  type="button"
                  className="mt-2 inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:text-sm disabled:opacity-70"
                  href={`tel:${primaryPhone}`}
                  onClick={onCallClick}
                >
                  <AiOutlinePhone className="h-5 w-5" />
                  Llamar
                </a>

                {secondaryPhone ? (
                  <a
                    type="button"
                    className="mt-2 inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:text-sm disabled:opacity-70"
                    href={`tel:${secondaryPhone}`}
                    onClick={onCallClick}
                  >
                    <AiOutlinePhone className="h-5 w-5" />
                    Llamar secundario
                  </a>
                ) : null}

                <button
                  type="button"
                  className="mt-2 inline-flex w-full justify-center rounded-md border border-gray-300 hover:border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-600 shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
