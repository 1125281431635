import AnimatedIcon from "./AnimatedIcon";

export function EmptyState({ title }: { title?: string | JSX.Element }) {
  return (
    <div className="relative pt-8 px-4 h-[400px] overflow-hidden">
      <div className="absolute flex justify-center left-0 right-0 mx-auto top-20">
        <h3 className="text-lg sm:text-2xl font-light tracking-tight text-gray-800 text-center">
          {title || (
            <span>
              ¡Lo sentimos!
              <br />
              No encontramos resultados para tu búsqueda
            </span>
          )}
        </h3>
      </div>
      <div className="h-[400px]">
        <AnimatedIcon icon="crash" stopAt={1400} />
      </div>
    </div>
  );
}
